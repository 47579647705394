:root {
    --primaryFont: 'Poppins', sans-serif;
    /* --primaryFont: 'Montserrat', sans-serif; */
    /* --primaryFont: 'Raleway', sans-serif; */
}

/* *{
    font-family: 'Satisfy', cursive;
} */
/* .navbar--container{
    font-family: 'Satisfy', cursive;
} */